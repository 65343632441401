import React, { useState } from "react"
import ModalVideo from "react-modal-video"
import Img from "gatsby-image"

export default function Video({ img, url, channel = "youtube" }) {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  return (
    <div>
      <ModalVideo
        channel={channel}
        isOpen={isOpen}
        videoId={url}
        onClose={toggle}
      />
      <a
        className="portfolio-box js-modal-video"
        onClick={toggle}
        style={{
          borderRadius: "5px",
          overflow: "hidden",
          padding: "0px",
        }}
      >
        <Img className="img-fluid" fluid={img} />
        <div className="portfolio-box-caption">
          <div className="portfolio-box-caption-content">
            <div className="project-name">Click to view video</div>
          </div>
        </div>
      </a>
      {/* <button onClick={toggle}>Open</button> */}
    </div>
  )
}
